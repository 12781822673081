import React from 'react'
import {
    BooleanInput,
    Datagrid,
    DateInput,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin'
import PIQAErrorEdit from "./PIQAErrorEdit";

var date = new Date()
date.setDate(date.getDate() - 90)
date.setHours(0, 0, 0)


const filters = [
    <BooleanInput label="With proposals only" source="with_proposals" alwaysOn />,
    <DateInput label="Last skip date" source="last_skip_date" alwaysOn />,
    <ReferenceInput source="family" reference="family">
        <SelectInput optionText="id" />
        </ReferenceInput>,
    <ReferenceInput source="attribute_set" reference="attribute-set">
        <SelectInput optionText="id" />
    </ReferenceInput>,
    <ReferenceInput source="brand" reference="brand">
        <SelectInput optionText="id" />
    </ReferenceInput>,
    <ReferenceInput source="error_code" reference="error-code">
        <SelectInput optionText="id" />
    </ReferenceInput>,
    <TextInput label="MPN" source="mpn" />,
    <TextInput source="name" />,
    <ReferenceInput source="source" reference="source">
        <SelectInput optionText="id" />
    </ReferenceInput>,
    <SelectInput source="availability" choices={[
        { id: 'in stock', name: 'In stock' },
        { id: 'available', name: 'Available' },
        { id: 'unavailable', name: 'Unavailable' },
    ]} />
]

const PIQAErrorList = (props) => (
    <List {...props} title="PIQA errors" filters={filters} filterDefaultValues={{ with_proposals: true, last_skip_date: date }} sort={{ field: 'severity', order: 'DESC' }} exporter={false} perPage={25} bulkActionButtons={false}>
        <Datagrid expand={<PIQAErrorEdit />}>
            <TextField source='severity' />
            <TextField source='error_code' />
            <TextField source='family' />
            <TextField source='attribute_set' />
            <TextField source='brand' />
            <TextField label="MPN" source='mpn' />
            <TextField source='name' />
            <TextField source='color' />
            <TextField source='availability' />
        </Datagrid>
    </List>
)

export default PIQAErrorList