import { Layout } from 'react-admin'

const NoMenuLayout = (props) => (
    <Layout
        {...props}
        sidebar={() => false}
        menu={() => false}
    />
)

export default NoMenuLayout