import { fetchUtils } from 'react-admin'

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const jwt = localStorage.getItem('jwt')
    options.headers.set('Authorization', `Bearer ${jwt}`)
    return fetchUtils.fetchJson(url, options)
}

export default httpClient
